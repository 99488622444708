import { useState } from "react";
import "./App.css";
import GoogleButton from "./google";
import logo from "./logo.svg";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

import "firebaseui/dist/firebaseui.css";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  // connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBkTZUlGdtuY532beGbjU1C4LSCbfDJdII",
  authDomain: "vertical-album-400917.firebaseapp.com",
  projectId: "vertical-album-400917",
  storageBucket: "vertical-album-400917.appspot.com",
  messagingSenderId: "573351076517",
  appId: "1:573351076517:web:68c7f3bfe48a95b6fda34c",
  measurementId: "G-LWT00J25KT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const _auth = getAuth(app);
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);

function App() {
  const [user, setUser] = useState(null);
  const [response, setResponse] = useState(null);

  onAuthStateChanged(
    getAuth(),
    async (user) => {
      // console.log("user", user);
      setUser(user);
    },
    (error) => {
      console.error(error);
    }
  );

  const handleFunc = () => {
    console.log("HandleFunc Clicked.");
    const callAPI = httpsCallable(functions, "on_call_example");
    callAPI({ data: "Sample Data" }).then((result) =>
      setResponse(result.data.result)
    );
  };

  const handleLogin = () => {
    console.log("Login/Logout Button Clicked.");
    if (user) {
      signOut(_auth);
    } else {
      login();
    }
    setUser(user);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        Under Development!
      </header>
      <body className="App-body">
        <div>Log in to register early:</div>
        {GoogleButton(handleLogin, user ? "Logout" : null)}
        <p></p>
        {userRegistration(user)}
        <br></br>
        {apiTest(user, response, handleFunc)}
        {links}
      </body>
    </div>
  );
}

function login() {
  signInWithPopup(_auth, provider)
    .then((result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      console.log(
        "Authenticated " + result.user.displayName + " " + result.user.email
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // const email = error.customData.email;
      // const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Auth Popup Error:" + errorCode + " " + errorMessage);
    });
}

function userRegistration(user) {
  return (
    user && (
      <div>
        <div>
          <b>Registered!</b>
        </div>
        <div>
          <b>Name:</b> {user.displayName}
        </div>
        <div>
          <b>Email:</b> {user.email}
        </div>
      </div>
    )
  );
}

function apiTest(user, response, callback) {
  if (user == null) {
    return "";
  }
  if (user.displayName != "Addison Hammer") {
    return "";
  }
  return (
    <div>
      <Button variant="light" onClick={callback}>
        API Test
      </Button>{" "}
      <div>{response}</div>
      <br></br>
    </div>
  );
}

const links = (
  <div>
    <a
      className="App-link"
      href="https://x1pipeline.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Main Website
    </a>{" "}
    |{" "}
    <a
      className="App-link"
      href="privacy.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
  </div>
);

export default App;
